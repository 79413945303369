.__header_desc {
	width: 100vw;
	height: 50px;
	color: var(--attach_picker_tab_active_text);
}

.__header_desc ._hd_left:hover {
	cursor: pointer;
}
.__cardOutlineContainer {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-flow: row wrap;
	padding-bottom: 10px;
}

._cardOutlineItem:hover {
	cursor: pointer;
}

._cardOutlineItem {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-left:2px;
	margin-right:2px;
	margin-top:11px;
	margin-bottom:0px;

	border: 1px solid #979797;
	border-radius: 15px;
	
	width: 113px;

	padding-top: 10px;
	padding-bottom: 10px;
	flex: 0 0 30%;
}

@media (max-width: 600px) {
	._cardOutlineItem {
		flex: 0 0 45%;
	}
}
@media (max-width: 320px) {
	._cardOutlineItem {
		flex: 0 0 95%;
	}
}

._cardOutlineItemTitle {
	display: flex;
	justify-content: space-around;
	text-align: center;
	align-items: center;
	
	height: 50px;

	padding-top: 8px;
	padding-bottom: 6px;
	padding-left: 5px;
	padding-right: 5px;
}

._cardOutlineItemSubscriber {
	display: flex;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;

	font-size: 13px;
    line-height: 15px;
    color: var(--text_secondary);
    overflow: hidden;
    text-overflow: ellipsis;
}

._cardOutlineItemDescription {
	display: flex;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;

	font-size: 13px;
    line-height: 15px;
    color: var(--text_secondary);
    overflow: hidden;
    text-overflow: ellipsis;
}

._cardOutlineItemAmount {
	display: flex;
	justify-content: center;
	padding-top: 8px;
	padding-left: 5px;
	padding-right: 5px;
}

._cardOutlineItemAmountDescription {
	display: flex;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;
}

.__CardOutlineImageAvatar {
	max-width: 100px;
	border-radius: 50%;
}



.__online .Avatar__in:after {
	z-index: 9999;
	bottom: 1%;
	right: 1%;
	border: 2px solid var(--background_content);
	height: 9px;
	width: 9px;
	content: "";
	position: absolute;
	background-color: #8ac176;
	border-radius: 50%;
}

.__online_mobile .Avatar__in:after {
	z-index: 99999;
	bottom: 3%;
	right: 3%;
	height: 15px;
	width: 9px;
	background-color: transparent;
	background-image: url(../../static/media/mobile.024259c8.svg);
	border: 0px;
	border-radius: 2px;
	background-repeat: no-repeat;
}

.__cellTitle {
	display: flex;
	vertical-align: middle;
	align-items: center;
}

.__verifiedIcon {
	color: var(--icon_name);
	margin-left: 4px;
}


.__GroupHeaderImage {
	max-width: 100%;
}
.AllThumbnails {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	flex-wrap: wrap;
}

.AllThumbnailsMobile {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	-webkit-overflow-scrolling: touch !important;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
	height: 100%;
}

.AllThumbnailsMobile::-webkit-scrollbar {
	display: none;
}

.AllThumbnailsMobile::-webkit-scrollbar-button {
	display: none;
}

.ThumbnailBox {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ThumbnailBoxImg {
	object-fit: cover;
	width: 185px;
	height: 127px;
	padding: 3px;
}
.ThumbnailBoxImg:hover {
	cursor: pointer;
}

.ThumbnailBoxImgMobile {
	object-fit: cover;
	width: 100px;
	height: 70px;
	margin: 3px;
}

.showBoxImg {
	position: absolute;
}
.showBoxImg img {
	max-width: 100vw;
	max-height: 80vh;
}

.showBoxIcon28ChevronBack {
	position: absolute;
	z-index: 999;
	left: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
    height: 24px;
}
.showBoxIcon28ChevronForward {
	position: absolute;
	z-index: 999;
	right: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
    height: 24px;
}

.navSvg {
	width: 24px;
	height: 24px;
	background-color: rgba(0, 0, 0, .1);
	border-radius: 100%;
}
.navSvg:hover {
	cursor: pointer;
}

.showBoxIcon24Dismiss {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgb(255, 255, 255, .6);
    border-radius: 100%;
}
.showBoxIcon24Dismiss:hover {
	cursor: pointer;
}

/* fix VKUI*/
.PopoutWrapper {
	z-index: 9999;
}

.OtherCellStyle .Cell__main {
	padding-bottom: 0px;
}

.OtherInfoRowStyleTitle {
	justify-content: flex-end;
}

.OtherInfoRowStyleTitle .InfoRow__title {
	display: flex;
	justify-content: flex-end;
}
.WelcomeGallery {
	height: calc(100vh - 64px);
}
.WelcomeOneBlock {
	height: 100%;
}

.WelcomeTextBlock {
	padding-top: 54px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeTitle {
	font-size: 22px;
	text-align: center;
}

.WelcomeDescription {
	font-size: 16px;
	color: var(--content_placeholder_text);
}

.WelcomeImg {
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeImg img {
	max-width: 55vw;
}
.__badVersion {
	padding-top: 30vh;
	box-sizing: border-box;
	text-align: center;
	width: 100vw;
}

.__badVersion .__bd_item {
	width: 100vw;
}
:root {
	--evaluation_favorite_star: #e8d306;
	--wait_dots: #6287AE;

	--notify_text: rgb(18, 18, 18, 0.7);
	--notify_background_info: rgb(131, 133, 135, 0.3);
	--notify_border_info: rgb(131, 133, 135, 0.5);
	--notify_background_error: rgb(255, 107, 107, 0.3);
	--notify_border_error: rgb(255, 107, 107, 0.5);
	--notify_background_success: rgb(175, 212, 169, 0.3);
	--notify_border_success: rgb(175, 212, 169, 0.5);
}

/* светлые темы */
body[scheme="client_light"] {
	color: #fff;
	--notify_text: rgb(18, 18, 18, 0.7);
}
body[scheme="bright_light"] {
	color: #fff;
	--notify_text: rgb(18, 18, 18, 0.7);
}

/* темные темы */
body[scheme="client_dark"] {
	color: #fff;
	--notify_text: #fff;
}
body[scheme="space_gray"] {
	color: #fff;
	--notify_text: #fff;
}

body::-webkit-scrollbar-track {
	background-color: var(--separator_common);
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--attach_picker_tab_inactive_text);
	border-radius: 6px;
}

.flex_start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex_arrow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pb-0 {
	padding-bottom: 0px;
}
.pt-0 {
	padding-top: 0px;
}
.pl-0 {
	padding-left: 0px;
}
.pr-0 {
	padding-right: 0px;
}
.pt-5 {
	padding-top: 5px;
}
.pb-5 {
	padding-bottom: 5px;
}
.pr-5 {
	padding-right: 5px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}

.mb-10 {
	padding-bottom: 102px;
}

.minHeight-100 {
	min-height: 100px;
}

/*
	fix padding from form VKUI
*/

.__FormLayoutBlock .FormLayout__row {
	padding-top: 5px;
	padding-bottom: 5px;
}

.rotate-180 {
	-webkit-transform: rotate(180deg); 
	transform: rotate(180deg);
}

.ww-break {
	word-wrap: break-word;
}
