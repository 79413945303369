.__online .Avatar__in:after {
	z-index: 9999;
	bottom: 1%;
	right: 1%;
	border: 2px solid var(--background_content);
	height: 9px;
	width: 9px;
	content: "";
	position: absolute;
	background-color: #8ac176;
	border-radius: 50%;
}

.__online_mobile .Avatar__in:after {
	z-index: 99999;
	bottom: 3%;
	right: 3%;
	height: 15px;
	width: 9px;
	background-color: transparent;
	background-image: url(./svg/mobile.svg);
	border: 0px;
	border-radius: 2px;
	background-repeat: no-repeat;
}

.__cellTitle {
	display: flex;
	vertical-align: middle;
	align-items: center;
}

.__verifiedIcon {
	color: var(--icon_name);
	margin-left: 4px;
}