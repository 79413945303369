.OtherCellStyle .Cell__main {
	padding-bottom: 0px;
}

.OtherInfoRowStyleTitle {
	justify-content: flex-end;
}

.OtherInfoRowStyleTitle .InfoRow__title {
	display: flex;
	justify-content: flex-end;
}