:root {
	--evaluation_favorite_star: #e8d306;
	--wait_dots: #6287AE;

	--notify_text: rgb(18, 18, 18, 0.7);
	--notify_background_info: rgb(131, 133, 135, 0.3);
	--notify_border_info: rgb(131, 133, 135, 0.5);
	--notify_background_error: rgb(255, 107, 107, 0.3);
	--notify_border_error: rgb(255, 107, 107, 0.5);
	--notify_background_success: rgb(175, 212, 169, 0.3);
	--notify_border_success: rgb(175, 212, 169, 0.5);
}

/* светлые темы */
body[scheme="client_light"] {
	color: #fff;
	--notify_text: rgb(18, 18, 18, 0.7);
}
body[scheme="bright_light"] {
	color: #fff;
	--notify_text: rgb(18, 18, 18, 0.7);
}

/* темные темы */
body[scheme="client_dark"] {
	color: #fff;
	--notify_text: #fff;
}
body[scheme="space_gray"] {
	color: #fff;
	--notify_text: #fff;
}

body::-webkit-scrollbar-track {
	background-color: var(--separator_common);
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--attach_picker_tab_inactive_text);
	border-radius: 6px;
}

.flex_start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex_arrow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pb-0 {
	padding-bottom: 0px;
}
.pt-0 {
	padding-top: 0px;
}
.pl-0 {
	padding-left: 0px;
}
.pr-0 {
	padding-right: 0px;
}
.pt-5 {
	padding-top: 5px;
}
.pb-5 {
	padding-bottom: 5px;
}
.pr-5 {
	padding-right: 5px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}

.mb-10 {
	padding-bottom: 102px;
}

.minHeight-100 {
	min-height: 100px;
}

/*
	fix padding from form VKUI
*/

.__FormLayoutBlock .FormLayout__row {
	padding-top: 5px;
	padding-bottom: 5px;
}

.rotate-180 {
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg); 
	transform: rotate(180deg);
}

.ww-break {
	word-wrap: break-word;
}