.__cardOutlineContainer {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-flow: row wrap;
	padding-bottom: 10px;
}

._cardOutlineItem:hover {
	cursor: pointer;
}

._cardOutlineItem {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-left:2px;
	margin-right:2px;
	margin-top:11px;
	margin-bottom:0px;

	border: 1px solid #979797;
	border-radius: 15px;
	
	width: 113px;

	padding-top: 10px;
	padding-bottom: 10px;
	flex: 0 0 30%;
}

@media (max-width: 600px) {
	._cardOutlineItem {
		flex: 0 0 45%;
	}
}
@media (max-width: 320px) {
	._cardOutlineItem {
		flex: 0 0 95%;
	}
}

._cardOutlineItemTitle {
	display: flex;
	justify-content: space-around;
	text-align: center;
	align-items: center;
	
	height: 50px;

	padding-top: 8px;
	padding-bottom: 6px;
	padding-left: 5px;
	padding-right: 5px;
}

._cardOutlineItemSubscriber {
	display: flex;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;

	font-size: 13px;
    line-height: 15px;
    color: var(--text_secondary);
    overflow: hidden;
    text-overflow: ellipsis;
}

._cardOutlineItemDescription {
	display: flex;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;

	font-size: 13px;
    line-height: 15px;
    color: var(--text_secondary);
    overflow: hidden;
    text-overflow: ellipsis;
}

._cardOutlineItemAmount {
	display: flex;
	justify-content: center;
	padding-top: 8px;
	padding-left: 5px;
	padding-right: 5px;
}

._cardOutlineItemAmountDescription {
	display: flex;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;
}

.__CardOutlineImageAvatar {
	max-width: 100px;
	border-radius: 50%;
}